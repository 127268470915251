import {
  getCountryFullName,
  splitString,
  getIndividualUnderscored,
  getOrganizationUnderscored,
  getPeopleRelationshipCleaned,
  getBaseUrl,
  individualPageLabels,
} from "../../helpers";
import { Button } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../app/api/axios";
import { selectCurrentUserInfo } from "../../features/auth/authSlice";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import InfoIcon from "@mui/icons-material/Info";
import avatarPlaceholder from "../../res/avatar-placeholder.png";
import {
  faYoutube,
  faTwitter,
  faInstagram,
  faTiktok,
  faWikipediaW,
} from "@fortawesome/free-brands-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

const INDIVIDUAL_URL = "/individual/";
const INDIVIDUAL_IMAGE_URL = "/individual_images/";

const Individual = () => {
  const [emptyResponse, setEmptyResponse] = useState(false);

  let { name } = useParams();
  const navigate = useNavigate();

  const userInfo = useSelector(selectCurrentUserInfo);

  const [individual, setIndividual] = useState(null);

  useEffect(() => {
    const getIndividual = async () => {
      setEmptyResponse(false); // Needed so when the user navigates to an emtpy response page, then back to this one, the empty response message is not shown

      const { data } = await axios.get(INDIVIDUAL_URL + name);

      if (data.length === 0 || data[0] === null) {
        setEmptyResponse(true);
        setIndividual(null);
        return;
      }

      if (data[0].dob !== null) {
        data[0].dob = new Date(data[0].dob).toLocaleDateString();
      }
      if (data[0].date_of_death !== null) {
        data[0].date_of_death = new Date(
          data[0].date_of_death
        ).toLocaleDateString();
      }
      setIndividual(data[0]);
    };

    getIndividual();
  }, [name]);

  const navigateToEditPage = () => {
    navigate("/individualEdit/" + name);
  };

  const getImageSrc = () => {
    if (!individual) {
      return avatarPlaceholder;
    } else if (individual.image_filename) {
      return getBaseUrl() + INDIVIDUAL_IMAGE_URL + individual.image_filename;
    } else {
      return individual.individual_image_url;
    }
  };

  const getStudies = (tags) => {
    let tagsList = splitString(tags);
    let results = [];
    for (let i = 0; i < tagsList.length; i++) {
      // if (completedStudies.includes(tagsList[i])) {
      results.push(tagsList[i]);
      // }
    }
    return results;
  };

  return (
    <div className="main-section">
      {emptyResponse && <p>"{name}" not found</p>}
      {individual && (
        <article className="individuals-page">
          <div className="individual-details">
            <div className="individual-name-container">
              <h1>
                {individual.name} #{individual.id}
              </h1>
              {userInfo && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={navigateToEditPage}
                >
                  EDIT PAGE
                </Button>
              )}
            </div>
            <hr />
            <div className="individual-detail-section">
              {individual.native_name && (
                <p>
                  <b>{individualPageLabels.nativeName}</b>{" "}
                  {individual.native_name}
                </p>
              )}
              {individual.aliases && (
                <p>
                  <b>{individualPageLabels.aliases}</b> {individual.aliases}
                </p>
              )}
              {/* Style guarantees newlines in the text are rendered as newlines in the HTML */}
              <p style={{ "white-space": "pre-line" }}>
                <b>{individualPageLabels.biography}</b> {individual.biography}
              </p>
              {individual.nationality && (
                <p>
                  <b>{individualPageLabels.nationality}</b>{" "}
                  {getCountryFullName(individual.nationality)}
                </p>
              )}
              {individual.alma_mater && (
                <p>
                  <b>{individualPageLabels.almaMater}</b>{" "}
                  {individual.alma_mater}
                </p>
              )}
              {individual.spouse && (
                <p>
                  <b>{individualPageLabels.spouse}</b>
                  <Link to={getIndividualUnderscored(individual.spouse)}>
                    {individual.spouse}
                  </Link>
                </p>
              )}
            </div>

            {individual.tags && getStudies(individual.tags).length > 0 && (
              <div className="individual-detail-section">
                <div className="tooltip-div">
                  <Tooltip
                    className="tooltip"
                    placement="right-start"
                    title="These represent the studies which have been explicitly carried out by the creators of Social Sentinel."
                  >
                    <InfoIcon />
                  </Tooltip>
                  <h1>{individualPageLabels.studies}</h1>
                </div>
                <hr />
                <div className="individual-detail-section">
                  {getStudies(individual.tags).map((item, index) => (
                    <Link
                      className="blue-link"
                      to={getOrganizationUnderscored(item)}
                      key={index}
                    >
                      <p>{item}</p>
                    </Link>
                  ))}
                </div>
              </div>
            )}

            {individual.people_relations && (
              <div className="individual-detail-section">
                <h1>{individualPageLabels.relationsAndAssociates}</h1>
                <hr />
                <div className="individual-detail-section">
                  {splitString(individual.people_relations).map(
                    (item, index) => (
                      <Link
                        className="blue-link"
                        to={getPeopleRelationshipCleaned(item)}
                        key={index}
                      >
                        <p>{item}</p>
                      </Link>
                    )
                  )}
                </div>
              </div>
            )}

            {individual.organizational_relations && (
              <div className="individual-detail-section">
                <div className="tooltip-div">
                  <Tooltip
                    className="tooltip"
                    placement="right-start"
                    title="These categories represent the various organizations and groups this individual is a part of. These were gathered automatically."
                  >
                    <InfoIcon />
                  </Tooltip>
                  <h1>{individualPageLabels.organizationalTies}</h1>
                </div>
                <hr />
                <div className="individual-detail-section">
                  <p> {individual.organizational_relations}</p>
                </div>
              </div>
            )}
          </div>

          <div className="spacer"></div>

          <div className="individual-image-container">
            {individual && (
              <img className="individual-image" src={getImageSrc()} alt="alt" />
            )}

            <div className="individual-image-details">
              {individual.gender && (
                <p>
                  <b>{individualPageLabels.gender}</b>{" "}
                  {individual.gender === 1 ? "Male" : "Female"}
                </p>
              )}

              {individual.lgbt !== null && individual.lgbt !== -1 && (
                <p>
                  <b>{individualPageLabels.sexuality}</b>
                  {individual.lgbt === 0
                    ? "Straight"
                    : individual.lgbt === 1
                    ? "Gay"
                    : "Trans"}
                </p>
              )}

              {individual.dob && (
                <p>
                  <b>{individualPageLabels.dob}</b> {individual.dob}
                </p>
              )}

              {individual.date_of_death && (
                <p>
                  <b>{individualPageLabels.dateOfDeath}</b>{" "}
                  {individual.date_of_death}
                </p>
              )}

              {individual.ethnicity && (
                <p>
                  <b>{individualPageLabels.ethnicity}</b>
                  {individual.ethnicityString}
                </p>
              )}
              {individual.ethnicity_notes && (
                <p>
                  <b>*</b> {individual.ethnicity_notes}
                </p>
              )}
              {individual.source && (
                <div className="sources-div">
                  <b>{individualPageLabels.sources}</b>
                  {splitString(individual.source).map((item, index) => (
                    <a href={item} target="_blank" rel="noreferrer">
                      <p>[{index + 1}]</p>
                    </a>
                  ))}
                </div>
              )}

              {(individual.wikipedia ||
                individual.youtube ||
                individual.twitter ||
                individual.more_info ||
                individual.instagram ||
                individual.tiktok) && (
                <div className="social-media-links">
                  {individual.wikipedia && (
                    <a
                      href={individual.wikipedia}
                      className="social-media-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Tooltip
                        className="tooltip"
                        placement="top"
                        title={individual.name + "'s Wikipedia Entry"}
                      >
                        <FontAwesomeIcon icon={faWikipediaW} />
                      </Tooltip>
                    </a>
                  )}
                  {individual.youtube && (
                    <a
                      href={individual.youtube}
                      className="social-media-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Tooltip
                        className="tooltip"
                        placement="top"
                        title={individual.name + "'s YouTube Channel"}
                      >
                        <FontAwesomeIcon icon={faYoutube} />
                      </Tooltip>
                    </a>
                  )}
                  {individual.twitter && (
                    <a
                      href={individual.twitter}
                      className="social-media-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Tooltip
                        className="tooltip"
                        placement="top"
                        title={individual.name + "'s X Account"}
                      >
                        <FontAwesomeIcon icon={faTwitter} />
                      </Tooltip>
                    </a>
                  )}
                  {individual.instagram && (
                    <a
                      href={individual.instagram}
                      className="social-media-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Tooltip
                        className="tooltip"
                        placement="top"
                        title={individual.name + "'s Instagram Account"}
                      >
                        <FontAwesomeIcon icon={faInstagram} />
                      </Tooltip>
                    </a>
                  )}
                  {individual.tiktok && (
                    <a
                      href={individual.tiktok}
                      className="social-media-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Tooltip
                        className="tooltip"
                        placement="top"
                        title={individual.name + "'s TikTok Account"}
                      >
                        <FontAwesomeIcon icon={faTiktok} />
                      </Tooltip>
                    </a>
                  )}
                  {individual.more_info && (
                    <a
                      href={individual.more_info}
                      className="social-media-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Tooltip
                        className="tooltip"
                        placement="top"
                        title={individual.name + "'s Website"}
                      >
                        <FontAwesomeIcon icon={faGlobe} />
                      </Tooltip>
                    </a>
                  )}
                </div>
              )}
              <div className="contributed-by-section">
                {individual.contributed_by &&
                individual.contributed_by &&
                individual.contributed_by_link ? (
                  <p>
                    Contributed By:{" "}
                    <a
                      href={individual.contributed_by_link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {individual.contributed_by}
                    </a>
                  </p>
                ) : (
                  <p>Contributed By: {individual.contributed_by}</p>
                )}
              </div>
            </div>
          </div>
        </article>
      )}
    </div>
  );
};

export default Individual;
