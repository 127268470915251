import { Link } from "react-router-dom";
import SearchBar from "../SearchBar/SearchBar";
import AuthUserWidget from "../AuthUserWidget/AuthUserWidget";
import { selectCurrentUserInfo } from "../../features/auth/authSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const Navbar = () => {
  const navigate = useNavigate();

  const userInfo = useSelector(selectCurrentUserInfo);

  const navigateToSubmitPage = () => {
    navigate("/individualSubmission/");
  };

  return (
    <nav className="navbar">
      <a href="/">
        <h1>Social Sentinel--ALPHA</h1>
      </a>
      <SearchBar />
      <div className="links">
        <Link to="/about">About</Link>
        <Link to="/support">Support Us</Link>
        <Link to="/memegenerator">Meme Generator</Link>
        <div className="navbar-user-and_new_entry_div">
          <AuthUserWidget />
          {userInfo && (
          <Button
            variant="contained"
            onClick={navigateToSubmitPage}
            size="small"
          >
            SUBMIT NEW ENTRY
          </Button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
