import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import useImage from "../../hooks/useImage";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "./memeGenerator.css";

import {
  getOrganizationMemeTagline,
  getOrganizationLogo,
  getOrganizationUnderscored,
} from "../../helpers";
import { MemePortrait } from "../";
import axios from "../../app/api/axios";

const SUMMARY_BY_ORG_URL = "/allindividualsbyorg/";

const MemeGenerator = () => {
  const memeLogoClass = "meme-logo-image";

  // Grid Constants
  const columnSpacing = 1;
  const nameSliceIndex = 5; // Defines how many grid element will appear on the first row of the meme.

  const numColumns1 = 9;
  const numColumns2 = 10;
  const numColumns3 = 12;
  const numColumns4 = 16;

  const { orgName } = useParams();
  const orgNameSpaces = orgName.replace(/_/g, " ");

  const [nameList, setNameList] = useState(null);

  useEffect(() => {
    const getNameList = async () => {
      const { data } = await axios.get(SUMMARY_BY_ORG_URL + orgName);
      console.log(data);

      setNameList(data);
    };
    getNameList();
  }, [orgName]);

  const { image } = useImage(orgName + ".png");

  return (
    <div>
      <Link to={getOrganizationUnderscored(orgName)}>
        <button type="button">See Org Details</button>
      </Link>
      {nameList && (
        <Box className="meme-image" sx={{ flexGrow: 1 }}>
          <div className="meme-image-first-row-container">
            <div className="meme-image-logo-container">
              {getOrganizationLogo(orgNameSpaces, memeLogoClass)}
              <h1>{getOrganizationMemeTagline(orgNameSpaces)}</h1>
            </div>

            <Grid
              container
              spacing={{ xs: columnSpacing }}
              columns={{ xs: 5 }}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              {nameList.slice(0, nameSliceIndex).map((item, index) => {
                return (
                  <Grid
                    className="meme-image-first-row"
                    item
                    xs={1}
                    key={index}
                  >
                    <MemePortrait individual={item} fontSize={16} />
                  </Grid>
                );
              })}
            </Grid>
          </div>

          <Grid
            container
            className="meme-image-second-row"
            spacing={{ xs: columnSpacing }}
            columns={{ xs: numColumns1 }}
          >
            {nameList.slice(5, 23).map((item, index) => {
              return (
                <Grid item xs={1} key={index}>
                  <MemePortrait individual={item} fontSize={16} />
                </Grid>
              );
            })}
          </Grid>

          <Grid
            container
            className="meme-image-third-row"
            spacing={{ xs: columnSpacing }}
            columns={{ xs: numColumns2 }}
          >
            {nameList.slice(23, 33).map((item, index) => {
              return (
                <Grid item xs={1} key={index}>
                  <MemePortrait individual={item} fontSize={16} />
                </Grid>
              );
            })}
          </Grid>

          <Grid
            container
            className="meme-image-fourth-row"
            spacing={{ xs: columnSpacing }}
            columns={{ xs: numColumns3 }}
          >
            {nameList.slice(33, 45).map((item, index) => {
              return (
                <Grid item xs={1} key={index}>
                  <MemePortrait individual={item} fontSize={13} />
                </Grid>
              );
            })}
          </Grid>

          <Grid
            container
            spacing={{ xs: columnSpacing }}
            columns={{ xs: numColumns4 }}
          >
            {nameList.slice(45).map((item, index) => {
              return (
                <Grid className="meme-image-fifth-row" item xs={1} key={index}>
                  <MemePortrait individual={item} fontSize={12} />
                </Grid>
              );
            })}
          </Grid>
          <div className="meme-image-context-box">
            <h3 className="meme-image-context-box-main-text">
              owijefoiawjf oijawofi awoiefjawof jawoiefjawofoi awojifawjio
              fojiawefjiowf awjioefio awef awf awoeif awjoef awefjio awef
              owijefoiawjf oijawofi awoiefjawof jawoiefjawofoi awojifawjio
              fojiawefjiowf awjioefio awef awf awoeif awjoef awefjio awef
              owijefoiawjf oijawofi awoiefjawof jawoiefjawofoi awojifawjio
              fojiawefjiowf awjioefio awef awf awoeif awjoef awefjio awef
              owijefoiawjf oijawofi awoiefjawof jawoiefjawofoi awojifawjio
              fojiawefjiowf awjioefio awef awf awoeif awjoef awefjio awef
              owijefoiawjf oijawofi awoiefjawof jawoiefjawofoi awojifawjio
              fojiawefjiowf awjioefio awef awf awoeif awjoef awefjio awef
              owijefoiawjf oijawofi awoiefjawof jawoiefjawofoi awojifawjio
              fojiawefjiowf awjioefio awef awf awoeif awjoef awefjio awef
            </h3>
          </div>
        </Box>
      )}
    </div>
  );
};

export default MemeGenerator;
