import "./organization.css";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getCountryFullName,
  getIndividualUnderscored,
  getMemeUnderscored,
  getOrganizationLogo,
  splitString,
  getEthnicityPercentageByCountry,
  chartColors,
} from "../../helpers";
import { Chart } from "react-google-charts";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import axios from "../../app/api/axios";

const ORGANIZATION_URL = "/organization/";
const ORG_REPRESENTATION_URL = "/orgEthnicRepresentation/";

const Organization = () => {
  const [org, setOrg] = useState(null);
  const [pieData, setPieData] = useState(null);
  const [barData, setBarData] = useState(null);
  let { orgName } = useParams();

  useEffect(() => {
    const getOrg = async () => {
      const { data } = await axios.get(ORGANIZATION_URL + orgName);
      setOrg(data[0]);
    };

    let emptyPieData = [
      ["Ethnicity", "Count"],
      ["Jewish", 0],
      ["White", 0],
      ["Black", 0],
      ["MENA", 0],
      ["Hispanic", 0],
      ["East Asian", 0],
      ["Southeast Asian", 0],
      ["South Asian", 0],
      ["Other", 0],
      ["Mixed", 0],
      ["Unknown", 0],
    ];

    let emptyBarData = [
      [
        "Ethnicity",
        "Over / Under Representation ",
        { role: "style" },
        {
          sourceColumn: 0,
          role: "annotation",
          type: "string",
          calc: "stringify",
        },
      ],
      ["Jewish", 0, chartColors.Jewish, null],
      ["White", 0, chartColors.White, null],
      ["Black", 0, chartColors.Black, null],
      ["MENA", 0, chartColors.MENA, null],
      ["Hispanic", 0, chartColors.Hispanic, null],
      ["East Asian", 0, chartColors.EastAsian, null],
      ["Southeast Asian", 0, chartColors.SoutheastAsian, null],
      ["South Asian", 0, chartColors.SouthAsian, null],
    ];

    const getAllIndividuals = async () => {
      const { data } = await axios.get(ORG_REPRESENTATION_URL + orgName);

      let temp = emptyPieData;
      for (let i = 1; i < data.length; i++) {
        for (let j = 1; j < temp.length; j++) {
          if (data[i].name === temp[j][0]) {
            temp[j][1] = data[i].count;
            break;
          }
        }
      }
      setPieData(temp);

      temp = emptyBarData;
      let total = 0;

      for (let i = 1; i < data.length; i++) {
        total += data[i].count;
      }

      for (let i = 1; i < data.length; i++) {
        for (let j = 1; j < temp.length; j++) {
          if (data[i].name === temp[j][0]) {
            let subgroupPercentage = data[i].count / total;
            let totalPopulationPercentage = getEthnicityPercentageByCountry(
              data[i].name,
              data[0].name
            );
            temp[j][1] = parseFloat(
              (
                (subgroupPercentage / totalPopulationPercentage) * 100 -
                1
              ).toFixed(2)
            );
            break;
          }
        }
      }

      setBarData(temp);
    };

    getOrg();
    getAllIndividuals();
  }, [orgName]);

  const barChartOptions = {
    title: "Over / Under Representation by Ethnicity",
    width: 1000,
    height: 400,
    bar: { groupWidth: "95%" },
    legend: { position: "none" },
  };

  const options = {
    title: "Ethnic Makeup of " + orgName,
    colors: [
      chartColors.Jewish,
      chartColors.White,
      chartColors.Black,
      chartColors.MENA,
      chartColors.Hispanic,
      chartColors.EastAsian,
      chartColors.SoutheastAsian,
      chartColors.SouthAsian,
      chartColors.Other,
      chartColors.Mixed,
      chartColors.Unknown,
    ],
  };

  return (
    <div className="main-section">
      {org && (
        <article>
          <div className="individuals-page">
            <div className="individuals-details">
              <Link to={getMemeUnderscored(orgName)}>
                <Button size="small" variant="contained">
                  See All Members
                </Button>
              </Link>
              <div className="spacer" />

              <h1>{org.name}</h1>
              <hr />
              <div className="individual-detail-section">
                {org.summary && (
                  <p>
                    <b>SUMMARY:</b> {org.summary}
                  </p>
                )}
              </div>

              <h1>Prominent Members</h1>
              <hr />
              <div className="individual-detail-section">
                {splitString(org.prominent_individuals).map(
                  (individualName) => (
                    <Link to={getIndividualUnderscored(individualName)}>
                      <p>{individualName}</p>
                    </Link>
                  )
                )}
              </div>
            </div>

            <div className="spacer" />

            <div className="individual-image-container">
              {org && getOrganizationLogo(org.name, "organization-page-logo")}
              <div className="individual-image-details">
                {org.industry && (
                  <p>
                    <b>INDUSTRY:</b> {org.industryString}
                  </p>
                )}
                {org.country && (
                  <p>
                    <b>BASED IN:</b> {getCountryFullName(org.country)}
                  </p>
                )}
                {org.source && (
                  <p>
                    <a href={org.source} target="_blank" rel="noreferrer">
                      SOURCE
                    </a>
                  </p>
                )}
              </div>
            </div>
          </div>
          <Chart
            chartType="PieChart"
            data={pieData}
            options={options}
            width={"100%"}
            height={"400px"}
          />
          <Chart
            chartType="BarChart"
            width="100%"
            height="400px"
            data={barData}
            options={barChartOptions}
          />
        </article>
      )}
    </div>
  );
};

export default Organization;
